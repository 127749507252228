import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationPrivacyPolicy from '../assets/images/illustrations/Privacy_policy.png';

const PrivacyPage = () => (
  <>
    <SEO title="Privacy Policy" />
    <div className="common-page privacy-page">
      <HeadingSection h1="Privacy Policy" image={imageIllustrationPrivacyPolicy} />
      <SecondSection />
    </div>
  </>
);

const SecondSection = () => (
  <section className="second-section">
    <div className="container">
      <p>
        This privacy policy applies between you, the User of this Website and Sprive Limited, the
        owner and provider of this Website. Sprive Limited takes the privacy of your information
        very seriously. This privacy policy applies to our use of any and all Data collected by us
        or provided by you in relation to your use of the Website.
      </p>
      <h4>1. Why we have a Privacy Notice</h4>
      <p>
        We are committed to respecting and protecting your privacy. This Privacy Notice sets out the
        type of information we collect from you and what we do with that information.
      </p>
      <h4>2. About us</h4>
      <p>
        For the purpose of this Privacy Notice, Sprive Limited, a company incorporated in England
        and Wales with registered number 12102682 whose registered office is at Cannon Place, 78 Cannon Street, 
        London, EC4N 6AF is the ‘Data Controller’. Sprive Limited are registered with
        the Information Commissioner’s Office. Our registration can be seen at{' '}
        <a
          href="https://ico.org.uk/ESDWebPages/Entry/ZA551896"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ico.org.uk/ESDWebPages/Entry/ZA551896
        </a>
      </p>

      <p>
        Sprive has appointed a Data Protection Officer who is responsible for overseeing questions
        in relation to this Privacy Notice, including any requests to exercise your legal rights. To
        contact our Data Protection Officer, please address:
      </p>
      <p>
        (a) emails to <a href="mailto:privacy@sprive.com" type="email">
            privacy@sprive.com
          </a>; or
        <br />
        (b) postal correspondence to the Data Protection Officer, Sprive Limited, Cannon Place, 78 Cannon Street, 
        London, EC4N 6AF
      </p>
      <p>We will ensure your query is responded to by our Data Protection Officer.</p>

      <h4>3. Other relevant policies and terms</h4>
      <p>
        This Privacy Notice should be read together with our Website & App Terms of Use, and, if you
        have signed up as a registered user on the Sprive app, our Customer Terms & Conditions.
      </p>

      <h4>4. Information we may collect:</h4>
      <p>
        Personal data, or personal information, means any information about an individual from which
        that person can be identified. It does not include data whether the identity has been
        removed (anonymous data).
      </p>

      <ol>
        <h4>4.1 Information you give us</h4>
        <p>
          During the course of creating an account with Sprive, the information you give to us may
          include:
        </p>
        <p>
          <strong>Identity Data</strong> - such as your first name, maiden name, last name, username
          or similar identifier, marital status, title, date of birth, address and employment
          history, and citizenship
        </p>
        <p>
          <strong>Contact Data</strong> - such as your address, email address and telephone number
        </p>
        <p>
          <strong>Financial Data</strong> - such as your bank account details, your income and outgoings, 
          credit history including details of any history of bankruptcy or county court judgements, 
          mortgages held, and, if the lender requires, direct debit details, for instance if your chosen 
          mortgage product requires any payment for things such as valuation. As an optional opt-in service, 
          we obtain your credit score and credit report from one of the UK leading Credit Reference Agencies 
          (CRA) on your behalf and refresh the report on a monthly basis.
        </p>
        <p>
          <strong>Transaction Data </strong>- such as your bank account transaction information -
          but not your login or password - for example, purchase item, price, location, date. This
          data is provided with your consent through a third party Open Banking provider.
        </p>
        <p>
          You may also provide us with certain information by filling in other forms, entering
          competitions or promotions or by corresponding with us by phone, email, text message or
          through our live in-App chat function.
        </p>
        <p>
          Sprive does not collect any special categories of personal data about you (this includes
          details about your race, ethnicity, religious or philosophical beliefs, sex life, sexual
          orientation, political opinions, trade union membership, information about your health or
          your biometric or genetic information). However, this may be subject to change if Sprive
          begins to sell mortgage protection insurance, which may require us to ask health related
          questions.
        </p>
        <p>
          However, it's important to note that some of the information you provide us with may
          become Special Category data when collated to form part of your mortgage application.{' '}
          <strong>
            We need your consent to process this data, and this is why we ask you to let us know
            that you agree to our Privacy Policy.
          </strong>{' '}
          This means we can continue to process your application with Special Category Personal Data
          included (as we wouldn't be able to provide our service without it).
        </p>
        <h4>4.2 Information that Sprive collects or receives from other sources</h4>
        <p>
          We work closely with third parties (for example: business partners, technical, payment and
          delivery service providers, advertising networks, tracking and analytics providers
          (including Google), search information providers, lead generation agencies and credit
          reference agencies) and may receive the following information about you when you visit our
          website or use our App:
        </p>
        <p>
          <strong>Technical Data</strong> - such as your IP address, URL, browser information,
          plug-in types, operating system and platform as well as your login information to the
          Sprive app.
        </p>
        <p>
          <strong>Profile Data</strong> - such as your preferences, feedback and survey responses.
        </p>
        <p>
          <strong>Usage Data</strong> - such as information about how you use our website, App,
          products and services.
        </p>
        <p>
          <strong>Marketing and Communications Data</strong> such as your preferences in receiving
          marketing from us and your communication preferences (which we may also collect directly
          from you).
        </p>
        <h4>4.3 Cookies</h4>
        <p>
          We, or our third party providers, may also use cookies to collect information about you. 
          This helps us to provide you with a good experience while you use our website and our App. 
          See our Cookie Policy for more details.
        </p>
        <p>
          We may use cookies to distinguish you from other users of our products or services. This
          helps us to provide you with a good experience, and also allows us to improve our products
          or services. A cookie is a small file of letters and numbers that we store on your browser
          or the hard drive of your device when you visit our website. Cookies send information back
          to the originating website on each subsequent visit, or to another website which
          recognises that cookie. Cookies also make it easier for you to log in and use our website.
        </p>
        <p>We may use the following cookies:</p>
        <p>
          <ul>
            <li>
              Strictly necessary cookies required for the operation of our products or services
              (including, for example, cookies that enable you to log into secure accounts and use
              interactive features);
            </li>
            <li>
              Analytical/performance cookies that allow us to recognise and count the number of
              visitors and users and see how they use the products or services (e.g. (without
              limitation) to help us improve the way our products or services work or are provided,
              by ensuring that users are finding what they are looking for easily);
            </li>
            <li>
              Functionality cookies to help us recognise you when you return to our website (this
              enables us to e.g. (without limitation) personalise our content for you, greet you by
              name and remember your preferences, such as choice of language or region).
            </li>
            <li>
              Tracking cookies. These enable us to track use of content from the Platform (on third
              party services, such as posts on social media networks), in accordance with your third
              party settings.
            </li>
            <li>
              Targeting cookies to record your visit to our website, the pages you have visited and
              the links you have followed. We may use this information to make our products and
              services and the information displayed on it, which we reasonably think is more
              relevant to your interests. We may also share this information with third parties for
              this purpose.
            </li>
          </ul>
        </p>
        <p>
          You can block or disable cookies by activating the setting on your website browser that
          allows you to refuse the setting of all or some cookies. All browsers provide tools that
          allow you to control how you handle cookies: accept, reject or delete them. These settings
          are normally accessed via the ‘settings’, ‘preferences’ or ‘options’ menu of the browser
          you are using, but you could also look for a ‘help’ function or contact the browser
          provider. However, if you set your browser settings to block or disable all cookies
          (including essential cookies) you may not be able to access all or parts of our Platform
          for which we require the use of cookies.{' '}
        </p>
        <h4>4.4 Links to other websites</h4>
        <p>
          Our website or App may, from time to time, contain links to and from the websites of our
          partner networks, advertisers and affiliates. If you follow a link to any of these
          websites, please note that these websites have their own privacy policies and that we do
          not accept any responsibility or liability for these policies. Please check these policies
          before you submit any personal information to these websites.
        </p>
        <h4>4.5 If you fail to provide personal data</h4>
        <p>
          Where we need to collect personal data by law, or under the terms of a contract with you,
          and you fail to provide that data when requested, we may not be able to perform the
          contract and may therefore be unable to provide you with one or more of our products or
          services.
        </p>
        <p>
          We may also collect, use and share aggregated data such as statistical or demographic data
          for any purpose. Aggregated data may be derived from your personal data but is not
          considered personal data in law as this does not directly or indirectly reveal your
          identity.
        </p>
      </ol>
      <h4>5. How we use your personal data</h4>
      <p>
        We will only use your personal data when the law allows us to. Most commonly we will use
        your personal data:
      </p>
      <p>
        (a) to provide you with the products and services you have selected;
        <br />
        (b) where it is necessary for our legitimate interests (or those of a third party) and your
        interests and fundamental rights do not override those interests; and
        <br />
        (c) where we need to comply with a legal or regulatory obligation.
      </p>
      <p>
        We have set out below a description of all the ways we plan to use your personal data, and
        which of the legal bases we rely on to do so. We have also identified what our legitimate
        interests are where appropriate.
      </p>

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>
                <h4>Purpose/Activity</h4>
              </th>
              <th>
                <h4>Type of data</h4>
              </th>
              <th>
                <h4>Lawful basis for processing</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>To register you as a customer</p>
              </td>
              <td>
                <li>Identity</li>
                <li>Contact</li>
                <li>Financial</li>
              </td>
              <td>
                <li>Consent</li>
                <li>Performance of a contract with you</li>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To process and deliver selected products and services, including mortgage
                  switching and facilitating mortgage overpayments
                </p>
              </td>
              <td>
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Financial</li>
                <li>Transaction</li>
                <li>Marketing and Communications</li>
                <li>Technical</li>
                <li>Usage </li>
              </td>
              <td>
                <li>Consent</li>
                <li>Performance of a contract with you</li>
                <li>Necessary to comply with a legal obligation</li>
              </td>
            </tr>
            
            <tr>
              <td>
                <p>
                To monitor your mortgage and let you know when a better deal becomes available
                </p>
              </td>
              <td>
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Financial</li>
                <li>Transaction</li>
                <li>Marketing and Communications</li>
                <li>Technical</li>
                <li>Usage</li>
              </td>
              <td>
                <li>Consent</li>
                <li>Performance of a contract with you</li>
                <li>Necessary for our legitimate interests (to develop our products/services and grow our business)</li>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  To manage our relationship with you which will include:
                  <br />
                  (a) Notifying you about changes to our terms or Privacy Policy
                  <br />
                  (b) Asking you to leave a review, take a survey, or participate in a prize draw
                  <br />
                  (c) Notifying you when your product is near expiry or when changes occur that
                  would merit our re-engagement.
                </p>
              </td>
              <td>
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Marketing and Communications</li>
                <li>Technical</li>
                <li>Usage</li>
              </td>
              <td>
                <li>Consent</li>
                <li>Performance of a contract with you</li>
                <li>Necessary to comply with a legal obligation</li>
                <li>
                  Necessary for our legitimate interests (to keep our records updated and to study
                  how customers use our products/services, to develop them, and to grow our
                  business)
                </li>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To administer and protect our business, App and website), including, security,
                  troubleshooting, data analysis, testing, system maintenance, support, reporting
                  and hosting
                </p>
              </td>
              <td>
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Marketing and Communications</li>
                <li>Transaction</li>
                <li>Technical</li>
                <li>Usage</li>
                <li>Financial</li>
              </td>
              <td>
                <li>Consent</li>
                <li>
                  Necessary for our legitimate interests (for running our business, provision of
                  administration and IT services, network security, to prevent fraud, and in the
                  context of any business reorganisation
                </li>
                <li>Necessary to comply with a legal obligation</li>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To deliver relevant App and website content and advertisements to you and measure
                  or understand the effectiveness of the advertising we serve to you
                </p>
              </td>
              <td>
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Marketing and Communications</li>
                <li>Transaction</li>
                <li>Technical</li>
                <li>Usage</li>
              </td>
              <td>
                <li>Consent</li>
                <li>
                  Necessary for our legitimate interests (to study how customers use our products
                  and services, to develop them, to grow our business, and to inform our marketing
                  strategy)
                </li>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To use data analytics to improve our App, website, products, services, marketing,
                  customer relationships and experiences
                </p>
              </td>
              <td>
                <li>Profile</li>
                <li>Transaction</li>
                <li>Technical</li>
                <li>Usage</li>
              </td>
              <td>
                <li>Consent</li>
                <li>
                  Necessary for our legitimate interests (to define types of customers for our
                  products and services, to keep our website and App updated and relevant, to
                  develop our business and to inform our marketing strategy)
                </li>
              </td>
            </tr>
            <tr>
              <td>
                <p>To de-identify your data (anonymise or aggregate)</p>
              </td>
              <td>
                <li>Identity </li>
                <li>Contact </li>
                <li>Profile </li>
                <li>Marketing and Communications </li>
                <li>Transaction </li>
                <li>Technical </li>
                <li>Usage </li>
                <li>Financial </li>
              </td>
              <td>
                <li>Consent </li>
                <li>
                  Necessary for our legitimate interests (to increase data security and protection,
                  to grow our business)
                </li>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To make suggestions and recommendations to you about products or services that may
                  be of interest to you, via electronic means or otherwise such as email, SMS, pop
                  ups or push notifications, targeted social media and our in-App secure
                  notification centre
                </p>
              </td>
              <td>
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Transaction</li>
                <li>Technical</li>
                <li>Usage</li>
              </td>
              <td>
                <li>Consent</li>
                <li>
                  Necessary for our legitimate interests (to develop our products and services, and
                  grow our business)
                </li>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4>6. Disclosure of your personal data</h4>
      <p>We may share your information with selected third parties including:</p>
      <ul>
        <li>Connect IFA Ltd</li>
        <li>Prepay Technologies Ltd</li>
        <li>Aggregation Providers</li>
        <li>KYC Providers</li>
        <li>Payment services providers</li>
        <li>Mortgage lenders</li>
        <li>
          Lead suppliers (if you were introduced to us by a third party including refer a friend)
        </li>
        <li>Credit Reference Agencies (CRA) for identity checking and to obtain your credit score and
            credit report on your behalf and to provide our services to you</li>
        <li>Mailchimp and advertising and distribution providers for marketing campaigns</li>
        <li>Trustpilot, Facebook, SurveyMonkey and Google for customer feedback and reviews.</li>
        <li>Social media platforms such as Facebook for marketing</li>
        <li>Support and analytics services to help with technical issues</li>
      </ul>
      <p>
        It's important to note that mortgage lenders and other third parties are also data
        controllers in their own right, and have their own privacy notices.
      </p>
      <p>
        <strong>Credit Reference Agencies (CRA){' '}</strong> - If you provide us consent to obtain your credit score and credit
        report to use our services we will obtain on your behalf, in accordance with our Terms, copies of
        your credit report and credit score from designated Credit Reference Agencies. In order to provide
        these services to you, we will share your information with such Credit Reference Agencies.
      </p>
      <p>
        The information which we provide to the CRA may be supplied by them to other organisations such
        as Fraud Prevention Agencies and used by those organisations for the purposes of checking identity,
        preventing fraud, tracing and collection of debt. The CRA may also use the data to undertake
        statistical analysis.
      </p>
      <p>
        If you choose to apply for a mortgage via Sprive, the lender will undertake a full credit check and
        provide you with further privacy information for that product.
      </p>
      <p>
        More information about each CRA and what it does with personal data is available <a
          href="https://www.equifax.co.uk/crain/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>.
      </p>
      <p>
      <strong>Mortgage advisers:{' '}</strong> If you have enquired about a mortgage or other financial product, we will share your personal data with an affiliated adviser who is able to provide financial advice in relation to your mortgage application or via Echo Ads Limited who will connect you to an advisor on our behalf.
      </p>
      <p>
        We require all third parties to respect the security of your personal data and to treat it
        in accordance with the law. We don't allow our third-party service providers to use your
        personal data for their own purposes and only permit them to process your personal data for
        specified purposes and in accordance with our instructions.
      </p>
      <p>
        We'll also share your personal data with the following data processors where necessary to
        fulfil our services and regulatory obligations:
      </p>
      <ul>
        <li>Amazon Web Services to provide our web services</li>
        <li>
          Professional advisers acting as processors or joint controllers including lawyers,
          bankers, auditors and insurers based in the UK in instances where such professional
          services are required.
        </li>
      </ul>
      <h4>7. Storing your information </h4>
      <ol>
        <h4>7.1 Security</h4>
        <p>
          We have put in place appropriate security measures to prevent your personal data from
          being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In
          addition, we limit access to your personal data to those employees, agents, contractors
          and other third parties who have a business need to know. They will only process your
          personal data on our instructions and are subject to a duty of confidentiality.
        </p>
        <p>
          We have put in place procedures to deal with any suspected personal data breach and will
          notify you and any applicable regulator of a breach where legally required to do so.
        </p>
        <p>
          Where we have given you, or where you have chosen, a password or personal identification
          number (PIN) which enables you to access certain parts of our website or App, you are
          responsible for keeping this password or PIN confidential. We ask you not to share this
          password or PIN with anyone.
        </p>
        <h4>7.2 Out of the EEA</h4>
        <p>
          Some of our external third parties are based outside the European Economic Areas (EEA) so
          their processing of your personal data will involve a transfer of data outside the EEA.
          Whenever we transfer your personal data out of the EEA, we ensure a similar degree of
          protection is afforded to it by:
        </p>
        <p>
          (a) only transferring your personal data to countries that have been deemed to provide an
          adequate level of protection for personal data by the European Commission;
          <br />
          (b) using specific contracts approved by the European Commission which give personal data
          the same protection is has in Europe (known as ‘model contracts’); or
          <br />
          (c) where we use providers based in the US, we may transfer data to them if they are part
          of the Privacy Shield which requires them to provide similar protection to personal data
          shared between Europe and the US.
        </p>
        <h4>7.3 Data retention</h4>
        <p>
          We will only keep your personal data for as long as necessary to fulfil the purposes we
          collected it for, including for the purposes of satisfying any legal, accounting or
          reporting requirements - as documented in Sprive’s data retention policy. To determine the
          appropriate retention period, we consider the amount, nature and sensitivity of the
          personal data, the potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data and whether we can
          achieve those purposes through other means, and the applicable legal and regulatory
          requirements.
        </p>
      </ol>
      <h4>8. Your rights</h4>
      <ol>
        <h4>8.1 Access to your personal data</h4>
        <p>
          You have the right to request access to your personal data (commonly known as a data
          subject access request. If you would like a copy of the information held on you, please
          contact our Data Protection Officer (see above for details).
        </p>
        <h4>8.2 Correction of your personal data</h4>
        <p>
          You have the to request a correction of the personal data that we hold about you. This
          enables you to have any incomplete or inaccurate data we hold about you corrected,
          although we may need to verify the accuracy of the new data you provide to us. It is
          important that the personal data we hold about you is accurate and current. Please keep us
          informed if your personal data changes.
        </p>
        <h4>8.3 Erasure of your personal data</h4>
        <p>
          You have the right to request erasure of your personal data. This enables you to ask us to
          delete or remove personal data where there is no good reason for us continuing to process
          it. You also have the right to ask us to delete or remove your personal data where you
          have successfully exercised your right to object to processing (see below), where we may
          have processed your information unlawfully or where we are required to erase your personal
          data to comply with local law. Note, however, that we may not always be able to comply
          with your request of erasure for specific legal reasons which will be notified to you, if
          applicable, at the time of your request.
        </p>
        <h4>8.4 Objection to processing of your personal data</h4>
        <p>
          You have the right to object to the processing of your personal data where we are relying
          on a legitimate interest (or those of a third party) and there is something about your
          particular situation which makes you want to object to processing on this ground as you
          feel it impacts on your fundamental rights and freedoms. You also have the right to object
          where we are processing your personal data for direct marketing purposes. In some cases,
          we may demonstrate that we have compelling legitimate grounds to process your information
          which override your rights and freedoms.
        </p>
        <h4>8.5 Restriction of processing your personal data</h4>
        <p>
          You have the right to request restriction of processing of your personal data. This
          enables you to ask us to suspend the processing of your personal data in the following
          scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the
          data is unlawful but you do not want us to erase it; (c) where you need us to hold the
          data even if we no longer require it as you need it to establish, exercise or defend legal
          claims; or (d) you have objected to our use of your data but we need to verify whether we
          have overriding legitimate grounds to use it.
        </p>
        <h4>8.6 Transfer of your personal data</h4>
        <p>
          You have the right to request the transfer of your personal data to you or to a third
          party. Subject to the rules and regulations that govern the products and services we
          provide, we will provide to you, or a third party you have chosen, your personal data in a
          structured, commonly used, machine-readable format. Note that this right only applies to
          automated information which you initially provided consent for us to use or where we used
          the information to perform a contract with you.
        </p>
        <h4>8.7 Withdrawing consent</h4>
        <p>
          You have the right to withdraw consent at any time where we are relying on consent to
          process your personal data. However, this will not affect the lawfulness of any processing
          carried out before you withdraw your consent. If you withdraw your consent, we may not be
          able to provide certain products or services to you. We will advise you if this is the
          case at the time you withdraw your consent.
        </p>
        <h4>8.8 Withdrawing consent to marketing</h4>
        <p>
          By submitting your personal information, you agree that Sprive may use your information
          for marketing purposes (including electronic marketing). Sprive will not disclose your
          information to any third party for marketing purposes, without your further consent. You
          have the right, at any time, to ask us not to process your personal information for
          marketing purposes.{' '}
        </p>
        <h4>8.9 Lodging complaints</h4>
        <p>
          You have the right to lodge a complaint with the Information Commissioner’s office for any
          processing carried out by Sprive. You can contact the{' '}
          <a
            href="https://ico.org.uk"
            target="_blank"
            rel="noopener noreferrer"
          >ICO
          </a>
          {' '}or telephone: 0303 123 1113.
        </p>
        <p>
          If you wish to exercise any of the rights above, please email us at{' '}
          <a href="mailto:privacy@sprive.com" type="email">
            privacy@sprive.com
          </a>
        </p>
      </ol>
      <h4>9. Changes to this Privacy Notice</h4>
      <p>
        We may make changes to this Privacy Notice, so please review it every now and then. If
        appropriate we will notify you by email about changes. Remember, you will be bound by this
        notice.
      </p>
    </div>
  </section>
);

export default PrivacyPage;
